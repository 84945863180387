import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import educatorPassportLogo from '../../images/educator-passport.png';
import FlashMessages from '../../common/components/misc/FlashMessages';
import LoginLayout from './LoginLayout';

const styles = theme => ({
  backToLoginLink: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
    },
    '&:visited': {
      color: theme.typography.color.primary,
    },
    fontSize: theme.typography.pxToRem(13),
    marginLeft: theme.typography.pxToRem(20),
  },
  loginActions: {
    marginTop: theme.typography.pxToRem(40),
  },
  loginHeader: {
    marginBottom: theme.typography.pxToRem(20),
  },
  loginLogo: {
    marginBottom: theme.typography.pxToRem(30),
  },
});

class ChangePasswordForm extends React.Component {

  render() {
    const { classes } = this.props;
    const validationErrors = this.props.validationErrors || {};

    return (
      <Fragment>
        <div className={classes.loginLogo}>
          <img src={educatorPassportLogo} />
        </div>
        <Typography className={classes.loginHeader} variant="h6">
          Forgot Password
        </Typography>
        <Typography className={classes.passwordHelp} variant="body2">
          Passwords must have at least 14 characters.
        </Typography>
        <Typography className={classes.passwordHelp} variant="body2">
          Passwords must include 1 uppercase letter, 1 lowercase letter, 1 digit and 1 symbol (#?!@$%^&*-).
        </Typography>
        <FlashMessages flashMessages={this.props.flashMessages}/>

        <form action="/users/password" method="post">
          <input name="_method" type="hidden" value="put" />
          <input name="authenticity_token" type="hidden" value={this.props.csrf} />
          <input type="hidden" name="user[reset_password_token]" value={this.props.resetPasswordToken} />
          <TextField
            variant="standard"
            autoFocus
            fullWidth
            error={validationErrors.hasOwnProperty('password')}
            helperText={validationErrors.hasOwnProperty('password') && validationErrors['password'][0]}
            label="New password"
            margin="normal"
            name="user[password]"
            type="password" />
          <TextField
            variant="standard"
            fullWidth
            error={validationErrors.hasOwnProperty('password_confirmation')}
            helperText={validationErrors.hasOwnProperty('password_confirmation') && validationErrors['password_confirmation'][0]}
            label="Confirm password"
            margin="normal"
            name="user[password_confirmation]"
            type="password" />
          <Grid
            container
            alignItems="center"
            className={classes.loginActions}
          >
            <Button color="primary" type="submit" variant="contained">
              Change Password
            </Button>
            <a
              className={classes.backToLoginLink}
              data-turbolinks="false"
              href="/users/sign_in"
            >
              Back to Login
            </a>
          </Grid>
        </form>
      </Fragment>
    );
  }

};

const StyledChangePasswordForm = withStyles(ChangePasswordForm, styles, { withTheme: true });
const ChangePasswordApp = (props) => {
  return (<LoginLayout>
    <StyledChangePasswordForm {...props}/>
  </LoginLayout>);
};

export default ChangePasswordApp;

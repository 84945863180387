import React from 'react';

import { withStyles } from 'tss-react/mui';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import WaveTextField from '../../../common/components/form/WaveTextField';
import ApiService from '../../../common/services/ApiService';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    left: '50%',
    margin: '0',
    top: '30%',
    transform: 'translate(-50%, -50%)',
  },
  closeModal: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3,
  },
  item: {
    paddingRight: 20,
  },
  textField: {
    width: 400
  }
});

class ChangePassword extends React.Component {

  state = {
    dirty: false,
    validationErrors: {},
    current_password: null,
    password: null,
    password_confirmation: null
  };

  render() {
    const { classes } = this.props;
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <div className={classes.paper}>
          <Grid container justifyContent="space-between" mb={2}>
            <Typography variant="h5">
              Change Password
            </Typography>
            <a onClick={this.props.onClose}>
              <CloseIcon className={classes.closeModal}/>
            </a>
          </Grid>
          <Typography className={classes.passwordHelp} variant="body2">
            Passwords must have at least 14 characters.
          </Typography>
          <Typography className={classes.passwordHelp} variant="body2">
            Passwords must include 1 uppercase letter, 1 lowercase letter, 1 digit and 1 symbol (#?!@$%^&*-).
          </Typography>
          <Typography>
            {this.state.errorMessage}
          </Typography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={6} className={classes.item}>
              <WaveTextField
                label="Current Password"
                type="password"
                {...this.makeFieldProps('current_password')}
              />
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <WaveTextField
                label="New Password"
                type="password"
                {...this.makeFieldProps('password')}
              />
            </Grid>
            <Grid item xs={6} className={classes.item}  >
              <WaveTextField
                label="Confirm New Password"
                type="password"
                {...this.makeFieldProps('password_confirmation')}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Button
                color="secondary"
                onClick={this.handleOnClickSubmit}
                size="small"
                variant="contained"
              >
                <Typography variant="button">Submit</Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }

  errorHelpers = (field) => {
    if (this.state.validationErrors.hasOwnProperty(field)) {
      return this.state.validationErrors[field][0];
    }
    return null;
  };

  errorStatus = (field) => {
    return this.state.validationErrors.hasOwnProperty(field)
  };

  makeFieldProps = (field) => {
    return {
      error: this.errorStatus(field),
      helperText: this.errorHelpers(field),
      name: field,
      onChange: this.handleInputChange,
      value: this.state[field],
    };
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var update = {};
    update[name] = value;
    this.setState(update);
  };

  setValidationErrors = (json) => {
    var errorMessage = json.title;

    if (json.messages.hasOwnProperty('base')) {
      errorMessage += " - " + json.messages.base.join(', ');
    }

    this.setState({
      dirty: true,
      errorMessage: errorMessage,
      validationErrors: json.messages
    });
  };

  handleOnClickSubmit = () => {
    const api = new ApiService();
    api.put('/api/v1/users/password', { user: { ...this.state } })
      .then((res) => {
        if(res.status == 200) {
          // need to sign back in
          window.location  = '/';
        } else if (res.status == 422) {
          this.setValidationErrors(res.json);
        }
      })
      .catch((ex) => console.error(ex));
  };
}

export default withStyles(ChangePassword, styles);
